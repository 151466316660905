import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { routes } from './operations-tracking-routing.module';
import { MatDialog } from '@angular/material/dialog';

interface TrackingTab {
  route: string;
  name: string;
}

@Component({
  selector: 'app-operations-tracking',
  templateUrl: './operations-tracking.component.html',
  styleUrls: ['./operations-tracking.component.scss']
})
export class OperationsTrackingComponent implements OnInit, OnDestroy {
  trackingTabs: TrackingTab[] = [];
  activeTab: string = 'Alerts';
  routerSubscription: Subscription;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,) {
    this.trackingTabs = routes.filter((x) => x.component).map((x) => {
      return { name: x.data['breadcrumb'], route: x.path }
    });
    if (this.router.url.includes('admin')) {
      const index = this.trackingTabs.findIndex((x) => x.route === 'daily-summary');
      this.trackingTabs.splice(index, 1);
    }
    const defaultLocation = this.router.url.substring(this.router.url.lastIndexOf('/') + 1);
    this.activeTab = this.getActiveTab(defaultLocation);
  }

  ngOnInit(): void {
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const location = event.urlAfterRedirects.substring(event.urlAfterRedirects.lastIndexOf('/') + 1);
        this.activeTab = this.getActiveTab(location);
      });
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }

  setActiveTab(route: string): void {
    this.router.navigate([route], { relativeTo: this.route });
  }

  getActiveTab(route: string): string {
    return this.trackingTabs.find((x) => x.route === route)?.name;
  }

  handleParentRouting(path: string): void {
    window.parent.postMessage(
      {
        type: 'route-changed',
        message: path,
      },
      'https://kldevelopmenthost.z20.web.core.windows.net/'
    );
  }
}
