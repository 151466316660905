import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(public store: Store<any>) {
    store.subscribe((state) => {
      this.saveState(state);
    });
  }

  public init() {}

  public loadState() {
    try {
      const serializedState = localStorage.getItem('state');
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      // console.log('LOADSTATE ERROR', err)
    }
  }

  saveState(state) {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('state', serializedState);
    } catch (err) {
      console.log('SAVESTATE ERROR', err)
    }
  }
}
