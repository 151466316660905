import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActiveRoutesComponent } from './features/active-routes/active-routes.component';
import { AlertsComponent } from './features/alerts/alerts.component';
import { CompletedComponent } from './features/completed/completed.component';
import { CourierOrdersComponent } from './features/courier-orders/courier-orders.component';
import { DailySummaryComponent } from './features/daily-summary/daily-summary.component';
import { InProgressComponent } from './features/in-progress/in-progress.component';
import { LotteryComponent } from './features/lottery/lottery.component';
import { PendingRoutesComponent } from './features/pending-routes/pending-routes.component';
import { ScheduledComponent } from './features/scheduled/scheduled.component';
import { FinalFailedComponent } from './features/final-failed/final-failed.component';
import { StagedAssignmentsComponent } from './features/staged-assignments/staged-assignments.component';

export const routes: Routes = [
  { path: '', redirectTo: 'alerts'},
  { path: 'daily-summary', data: { breadcrumb: 'Daily Summary' }, component: DailySummaryComponent},
  { path: 'alerts', data: { breadcrumb: 'Alerts' }, component: AlertsComponent },
  { path: 'scheduled', data: { breadcrumb: 'Scheduled' }, component: ScheduledComponent },
  { path: 'lottery', data: { breadcrumb: 'Lottery' }, component: LotteryComponent },
  { path: 'in-progress', data: { breadcrumb: 'In Progress' }, component: InProgressComponent },
  { path: 'active-routes', data: { breadcrumb: 'Active Routes' }, component: ActiveRoutesComponent },
  { path: 'pending-routes', data: { breadcrumb: 'Pending Routes' }, component: PendingRoutesComponent },
  { path: 'completed', data: { breadcrumb: 'Completed' }, component: CompletedComponent },
  { path: 'courier-orders', data: { breadcrumb: 'Courier Orders' }, component: CourierOrdersComponent },
  { path: 'final-failed', data: { breadcrumb: 'Final Failed Collections' }, component: FinalFailedComponent },
  { path: 'staged', data: { breadcrumb: 'Staged Assignments' }, component: StagedAssignmentsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OperationsTrackingRoutingModule {}
