import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ModalSizes, ModalAction } from 'app/shared/modals/modal.interfaces';
import { TableActionTaken, TableColumn, TableDataMap, TableRow } from 'app/shared/table/table.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { Store } from '@ngrx/store';
import { authState, selectorUser } from 'app/auth/auth.reducer';
import { AuthUser } from 'app/interfaces/auth.interfaces';
import { environment } from 'environments/environment';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { mapDataToTable } from 'app/shared/table/table.helpers';
import axios from 'app/api/axios';
import { ManageFleetService } from 'app/dashboard/manage-fleet/manage-fleet.service';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { AssignedOrdersData, Driver, DriverTypeEnum } from './assign-driver.interface';
import { SettingsService } from 'app/dashboard/settings/settings.service';
import { Paginate, PaginateEnum, PaginationConfiguration } from 'app/shared/shared.interfaces';
import { forkJoin } from 'rxjs';
import { mfiVehicleType } from 'app/admin/admin.service';
import { HighlightHealthStates } from 'app/dashboard/trips.enums';
import { AssignedOrder } from '../../new-trips.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';

@Component({
  selector: 'app-assign-driverload',
  templateUrl: './assign-driverload.component.html',
  styleUrls: ['./assign-driverload.component.scss'],
})
export class AssignDriverloadComponent extends SimpleModalComponent<any, any> implements OnInit {
  public ModalSizes = ModalSizes;
  public iconTypes = IconTypes;
  uiColors = UiColors;
  public actions: ModalAction[] = [{ name: 'close', title: 'Close', isPrimary: false }];
  justifyContent = JustifyContent;
  all_drivers: Driver[] = [];
  paginatedContractors: Driver[] = [];
  paginatedPlusDrivers: Driver[] = [];
  plus_drivers: Driver[] = [];
  optimizeFlag: boolean = false;
  searchString: string = '';
  consignments;
  environment = environment;
  driverQueryString: string;
  driverSearchData: TableRow[];
  business_id: string;
  user_id: string;
  user: AuthUser;
  limit_warehouse = true;
  warehouse_id: string;
  DriverType = DriverTypeEnum;
  buttonTypes = ButtonTypes;
  contractorPaginationConfig: PaginationConfiguration = {
    currentPageIndex: 0,
    defaultDisplayAmount: 5,
    paginationDisplayAmounts: [5, 10, 25, 100],
    totalPages: 0,
  };
  plusDriverPaginationConfig: PaginationConfiguration = {
    currentPageIndex: 0,
    defaultDisplayAmount: 10,
    paginationDisplayAmounts: [10],
    totalPages: 0,
  };
  warehouseGeoData = { latitude: 0, longitude: 0}


  public driverSearchTableDataMap: TableDataMap = {
    cells: {
      id: { column: 'id', map: '_id' },
      name: { column: 'name', map: 'name' },
      phone: {
        column: 'phone',
        map: 'phone',
        displayPipeArgs: ['none'],
      },
      email: { column: 'email', map: 'email' },
    },
  };

  public driverTableColumns: TableColumn[] = [
    { name: 'id', displayName: 'Id', hidden: true },
    { name: 'name', displayName: 'Name' },
    { name: 'phone', displayName: 'Phone Number' },
    { name: 'email', displayName: 'Email' },
  ];

  public driverTableDataMap: TableDataMap = {
    cells: {
      id: { column: 'id', map: 'id' },
      name: { column: 'name', map: 'name' },
      phone: {
        column: 'phone',
        map: 'phone',
        displayPipeArgs: ['none'],
      },
      email: { column: 'email', map: 'email' },
    },
  };

  public plusDriverTableColumns: TableColumn[] = [
    { name: 'id', displayName: 'Id', hidden: true },
    { name: 'name', displayName: 'Name' },
    { name: 'phone', displayName: 'Phone Number' },
    { name: 'email', displayName: 'Email' },
    { name: 'vehicle', displayName: 'Vehicle' },
    { name: 'orderCount', displayName: 'Assigned Trips' },
    { name: 'parcelCount', displayName: 'Parcels' },
    { name: 'online', displayName: 'Status' },
    { name: 'distance', displayName: 'KM From WH' },
  ];

  public plusDriverTableDataMap: TableDataMap = {
    cells: {
      id: { column: 'id', map: 'id' },
      name: { column: 'name', map: 'name' },
      phone: {
        column: 'phone',
        map: 'phone',
        displayPipeArgs: ['none'],
      },
      email: { column: 'email', map: 'email' },
      distance: { column: 'distance', map: 'distanceToWarehouse' },
      vehicle: { column: 'vehicle', map: '', iconMap: ['vehicle'],
        iconFormat: mfiVehicleType,
        iconColor: UiColors.Blue,
        iconSizeRem: 2,},
      orderCount: {column: 'orderCount', map: 'orderCount'},
      parcelCount: {column: 'parcelCount', map: 'parcelCount'},
      online: {column: 'online', map: 'online', format: (map: any, item: any) => {
        return item[map] ? 'Online' : 'Offline';
      },
      highlightColorClass: (map: any, item: any) => {
        return item[map[0]] ? HighlightHealthStates.Good : HighlightHealthStates.Bad;
      },
      highlighted: true,
      highlightColorMap: ['online'],}
    },
  };

  plusDriverLoading: boolean = false;

  public driverPrimaryActions = [{ event: 'select', title: 'Select', icon: IconTypes.Check }];

  constructor(
    private store: Store<authState>,
    private fleetService: ManageFleetService,
    private settingsService: SettingsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.select(selectorUser).subscribe((next) => {
      this.user = next;
    });
    this.getDrivers();
    this.getPlusDrivers(this.warehouse_id);
  }

  handlePagination(
    $event: Paginate,
    arrayToUse: Driver[],
    configuration: PaginationConfiguration,
    _arrayToBeSet: DriverTypeEnum
  ): void {
    configuration.totalPages = Number((arrayToUse.length / $event.Amount).toFixed(0));
    if (configuration.totalPages === 0) {
      configuration.totalPages = 1;
    }
    switch ($event.Direction) {
      case PaginateEnum.Backward: {
        if (configuration.currentPageIndex - 1 < 0) {
          return;
        }
        configuration.currentPageIndex--;
        break;
      }
      case PaginateEnum.Forward: {
        if (configuration.currentPageIndex + 1 >= configuration.totalPages) {
          return;
        }
        configuration.currentPageIndex++;
        break;
      }
    }

    if (_arrayToBeSet === DriverTypeEnum.Contractor) {
      this.paginatedContractors = this.paginateArray(arrayToUse, $event.Amount, configuration.currentPageIndex);
    } else {
      const paginatedPlusDrivers = this.paginateArray(arrayToUse, $event.Amount, configuration.currentPageIndex);
      const driverIds = paginatedPlusDrivers.map((x) => x.id);
      if (driverIds.length === 0) {
        this.paginatedPlusDrivers = paginatedPlusDrivers;
        this.plusDriverLoading = false;
        return;
      }
      forkJoin([this.fleetService.getDriversState(driverIds), this.fleetService.getDriversAssignedOrders(driverIds)]).subscribe((res) => {
        paginatedPlusDrivers.forEach((x) => {
          const driverStateDoc = res[0].docs.find((doc) => doc.id === x.id)?.data();
          const driverAssignedOrderDoc = res[1].docs.find((doc) => doc.id === x.id)?.data();
          if (!driverStateDoc || !driverAssignedOrderDoc) {
            return;
          }
          const assignedOrdersData = this.getAssignedOrdersData(driverAssignedOrderDoc.AssignedOrders);
          x.online = driverStateDoc.online;
          x.onTrip = !!driverStateDoc.active_route_id;
          x.orderCount = assignedOrdersData.OrderCount;
          x.parcelCount = assignedOrdersData.ParcelCount;
          x.distanceToWarehouse = this.getCrowsFliesDistance(driverStateDoc.location?.latitude, driverStateDoc.location?.longitude, this.warehouseGeoData.latitude, this.warehouseGeoData.longitude)?.toPrecision(3);
        });
        this.paginatedPlusDrivers = [...paginatedPlusDrivers];
        this.plusDriverLoading = false;
      });
    }
  }

  paginateArray(array: Driver[], page_size: number, page_number: number): Driver[] {
    return array.slice(page_number * page_size, page_number * page_size + page_size);
  }

  getAssignedOrdersData(assignedOrders: AssignedOrder[]): AssignedOrdersData {
    let orderCount: number = 0;
    let parcelCount: number = 0;
    assignedOrders.forEach((x) => {
      if (x.CollectionDate.toDate().toDateString() === new Date().toDateString()) {
        orderCount++;
        parcelCount += x.ParcelCount;
      }
    });
    return { OrderCount: orderCount, ParcelCount: parcelCount };
  }


  getCrowsFliesDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6371; // Radius of the earth in kilometers
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in KM
    return !isNaN(d) ? d : null;
    function deg2rad(deg: number) {
      return deg * (Math.PI / 180)
    }
  }

  getDrivers(): void {
    this.all_drivers = undefined;
    if (this.limit_warehouse) {
      this.getWarehouseDrivers();
    } else {
      this.fleetService.getBusinessDrivers(this.business_id).then((data: Driver[]) => {
        this.all_drivers = data;
        this.handlePagination(
          { Amount: this.contractorPaginationConfig.defaultDisplayAmount, Direction: PaginateEnum.None },
          this.all_drivers,
          this.contractorPaginationConfig,
          DriverTypeEnum.Contractor
        );
      });
    }
  }

  getPlusDrivers(warehouseId: string): void {
    this.plusDriverLoading = true;
    this.settingsService
      .getPicupPlusDriversWithWarehouseGeocode(this.business_id, this.limit_warehouse ? warehouseId : null)
      .then((data) => {
        this.plus_drivers = data.output.drivers;
        this.warehouseGeoData = data.output.warehouse;
        this.handlePagination(
          { Amount: this.plusDriverPaginationConfig.defaultDisplayAmount, Direction: PaginateEnum.None },
          this.plus_drivers,
          this.plusDriverPaginationConfig,
          DriverTypeEnum.PlusDriver
        );
      });
  }

  handleAction($event: string): void {
    switch ($event) {
      case 'close':
        this.close();
        break;
    }
  }

  respondToTableAction($event: TableActionTaken): void {
    const driverId = $event.rows[0].cells.find((cell) => cell.column === 'id').value;
    switch ($event.action.event) {
      case 'select':
        this.result = { driverId, optimize: this.optimizeFlag };
        this.close();
        break;
    }
  }

  getWarehouseDrivers(): Promise<void> {
    return axios({
      method: 'POST',
      url: '/generic/cqrs/get-warehouse-drivers',
      data: {
        warehouse_id: this.warehouse_id,
      },
    }).then((response) => {
      this.all_drivers = response?.data;
      this.handlePagination(
        { Amount: this.contractorPaginationConfig.defaultDisplayAmount, Direction: PaginateEnum.None },
        this.all_drivers,
        this.contractorPaginationConfig,
        DriverTypeEnum.Contractor
      );
    });
  }

  findDriverByName(): Promise<void> {
    return axios({
      method: 'POST',
      url: '/generic/cqrs/query-drivers-by-name',
      data: {
        business_id: this.business_id,
        warehouse_id: this.limit_warehouse ? this.warehouse_id : null,
        name: this.driverQueryString,
        return_all: this.limit_warehouse ? false : this.user.is_admin,
      },
    }).then((drivers) => {
      this.driverSearchData = mapDataToTable(
        drivers.data.output,
        this.driverSearchTableDataMap,
        this.driverTableColumns
      );
    });
  }

  toggleLimitWarehouse(): void {
    this.limit_warehouse = !this.limit_warehouse;
    this.getDrivers();
    this.getPlusDrivers(this.warehouse_id);
  }
}
