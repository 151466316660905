import { Warehouse, UserPermissions, AuthUser } from 'app/interfaces/auth.interfaces';

export interface OperationsTrackingTableAction {
  Id: string;
  Action: OperationsTrackingTableActionEnum;
}

export enum OrderStateEnum {
  Alert = 'Alert',
  Lottery = 'Lottery',
  InProgress = 'In Progress',
  Scheduled = 'Scheduled',
  Completed = 'Completed',
  FinalFailed = 'Final Failed Collections',
  StagedAssignment = 'Staged Assignment'
}

export enum OperationsTrackingTableActionEnum {
  CopyId = 'CopyId',
  View = 'View',
  ViewLastMile = 'ViewLastMile',
  RestartLottery = 'RestartLottery'
}

export enum AssignedStatusEnum{
  All = 'All',
  Unassigned = 'Unassigned',
  Assigned = 'Assigned',
  AssignedToMe = 'Assigned to me'
}

export enum LastMileStateEnum {
  Active = 'Active',
  Pending = 'Pending'
}

export interface OrderSummary {
  CustomerReference: string;
  DriverNames: string[];
  FleetAllocation: string;
  FrontEndState: string;
  Id: string;
  IsRoundTrip: boolean;
  LastEventName: string;
  ParcelCount: number;
  ScheduledDate: firebase.default.firestore.Timestamp;
  TotalLotteryAttempts: number;
  Timestamp: firebase.default.firestore.Timestamp;
  Version: number;
  Quote: Quote;
  BucketId: string;
  Reservation: Reservation
  BusinessId: string;
  WarehouseId: string;
}

export interface Reservation{
  Timestamp: firebase.default.firestore.Timestamp;
  UserId: string;
  Username: string;
}

export interface Quote {
  DistanceInMeters: number;
  DurationInMinutes: number;
  Earnings: Earnings[]
}

export interface Earnings{
  fleet: string;
  amount: number;
}

export interface PieChartData {
  name: string;
  value: number;
}


export enum OnDemandToggle {
  all = 'All',
  onDemand = 'On-Demand',
  scheduled = 'Scheduled'
}

export interface OpTrBusinesses {
  branding: Branding,
  business_id: string;
  is_enterprise: boolean;
  name: string;
  phone: string;
}

export interface Branding{
  color: string
  description: string
  logo_url: string
  secondary_color: string
  text_color: string
}

export interface OpsTrackingStore {
  warehouses: Warehouse[];
  userPermissions: UserPermissions;
  user: AuthUser;
  businesses: OpTrBusinesses[];
}

export enum OrderTrackingTypeEnum {
  order = 'Order',
  lastMile = 'Last-Mile'
}
