import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Branding } from 'app/interfaces/businesses.interfaces';
import { USE_AUTH_INTERCEPTOR } from 'app/shared/interceptors/auth-token-context';
import { Order } from 'app/shared/shared.interfaces';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { WaybillInfo } from './models/waybill-info.model';
import { map, switchMap, take } from 'rxjs/operators';
import { FsDriverDetails } from './models/driver-details.model';
import { AuthService } from 'app/auth/auth.service';
import { TokenErrorEnum } from './sms-tracking.component';

const courierDriverInitialState = {
  id: null,
  name: null,
  acting_as: null,
  active_vehicle: null,
  phone: null,
  profile_url: null,
  courier_code: null,
  last_updated: null,
  location: {
    latitude: null,
    longitude: null,
  },
  isLoading: false
} as const;

@Injectable()
export class SmsTrackingService {
  private readonly _branding$ = new BehaviorSubject<Branding>({
    primary_color: '#27b7fc',
    secondary_color: '#2a2c3b',
    text_color: '#fff',
    logo_url: '/assets/img/picup-logo-white.svg',
    collection_sms_text: '',
    delivery_sms_text: '',
    description: '',
    polyline_color: '#27b7fc',
    map_lightmode_enabled: false,
    default_driver_icon: null,
    vehicle_icon_motorbike: null,
    vehicle_icon_car: null,
    vehicle_icon_small_van: null,
    vehicle_icon_large_van: null,
    collection_icon: null,
    delivery_icon: null,
    secondary_text_color: '#fff'

  });
  readonly branding$ = this._branding$.asObservable();

  private readonly _courierDriverDetails$ = new BehaviorSubject<FsDriverDetails | null>({
    ...courierDriverInitialState,
    isLoading: true,
    hasError: false
  });
  readonly courierDriverDetails$ = this._courierDriverDetails$.asObservable();

  // Named isLoading because this is the absolute minimum data the tracking page needs to load.
  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);
  readonly isLoading$ = this._isLoading$.asObservable();

  private _tokenError$ = new BehaviorSubject<TokenErrorEnum | null>(null);
  set tokenError(error: TokenErrorEnum | null) {
    this._tokenError$.next(error);
  }
  readonly tokenError$ = this._tokenError$.asObservable();

  constructor(private httpClient: HttpClient, private firestore: AngularFirestore, private authService: AuthService) { }

  getWaybillInfoByReference(order_waybill_number: string, isWaybillEncrypted: boolean = true): Observable<WaybillInfo[]> {
    const apiUrl = isWaybillEncrypted ? 'waybill-info-by-reference/' : 'waybill-info/';
    const orderWaybillNumber = isWaybillEncrypted ? order_waybill_number : `PCW${order_waybill_number}`;

    this._isLoading$.next(true);

    return this.httpClient.get<WaybillInfo[]>(`${environment.integration.rootUrl}integration/${apiUrl}${orderWaybillNumber}`, {
      context: new HttpContext().set(USE_AUTH_INTERCEPTOR, true)
    }).pipe(
      switchMap((waybillInfo: WaybillInfo[]) => {
        this._isLoading$.next(false);
        return of(waybillInfo);
      }),
    );
  }

  getOrderDetails(orderId: string): Observable<Order> {
    return this.firestore.collection<Order>('orders')
      .doc(orderId)
      .valueChanges()
  }

  getBranding(businessId: string): Observable<Branding> {
    return this.httpClient.get<Branding>(`${environment.integration.rootUrl}enterprise/${businessId}/get-branding`, {
      context: new HttpContext().set(USE_AUTH_INTERCEPTOR, true)
    }).pipe(
      switchMap((branding: Branding) => {
        if (branding.primary_color) {
          this._branding$.next(branding);
        }
        return this._branding$;
      })
    );
  }

  getDriverDetails(driverId: string): Observable<FsDriverDetails> {
    return this.firestore.collection<FsDriverDetails>('driver-details')
      .doc(driverId)
      .valueChanges();
  }

  getCourierDriverDetails(waybillNumber: string): Observable<FsDriverDetails> {
    return this.firestore.collection<FsDriverDetails>('courier-order-driver')
      .doc(waybillNumber)
      .valueChanges().pipe(
        switchMap((driverDetails: FsDriverDetails) => {
          if (driverDetails) {
            driverDetails = {
              ...driverDetails,
              isLoading: false,
              hasError: false
            }
          } else {
            driverDetails = {
              ...courierDriverInitialState,
              isLoading: false,
              hasError: true
            }
          }
          this._courierDriverDetails$.next(driverDetails);
          return this._courierDriverDetails$;
        })
      );
  }

  getDriverLastUpdated(): Observable<string> {
    return this._courierDriverDetails$.pipe(
      map((driverDetails: FsDriverDetails) => driverDetails?.last_updated)
    )
  }

  getOrderRoutePolyLine(activeRouteId: string): Observable<any> {
    return this.firestore.collection('route-polylines')
      .doc(activeRouteId)
      .valueChanges();
  }

  getFirestoreToken(waybill: string): void {
    this.httpClient.get(`${environment.integration.rootUrl}integration/waybill-token-by-reference/${waybill}`)
      .pipe(take(1)).subscribe({next: (res: waybillToken) => {
        this.connectToFireAuthWithToken(res.result)
      }, error: (error) => {
        if(error.error.message === 'expired'){
          this.tokenError = TokenErrorEnum.expired
        }
      }})
  }

  connectToFireAuthWithToken(IdToken: string): void {
    this.authService.tokenSignIn(IdToken)
  }

}

export interface waybillToken{
  result: string;
}
