<ng-container *ngIf="courierParcelSummaryData && !isAdmin">
    <h4>Overall Parcel Summary</h4>
    <ngx-charts-advanced-pie-chart [label]="'Total Parcels'" [view]="[400, 170]" [results]="courierParcelSummaryData">
    </ngx-charts-advanced-pie-chart>
</ng-container>
<h3>Active Courier Orders</h3>
<mat-form-field *ngIf="!(operationsTrackingService.isAdmin$ | async) && warehouses && warehouses?.length > 0 && (operationsTrackingService.teams$ | async).length > 0" appearance="standard">
  <mat-label>Team</mat-label>
  <mat-select [formControl]="teamsControl" multiple>
    <mat-option *ngFor="let team of (operationsTrackingService.teams$ | async); trackBy: trackByTeamName" 
      [value]="team.teamName" >
      {{team.teamName}}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field *ngIf="warehouses && warehouses?.length > 0" appearance="standard">
  <mat-label>Warehouses</mat-label>
  <mat-select
    [formControl]="warehouseControl"
    multiple>
    <input-field
      (valueChanged)="filterWarehouse($event)"
      [type]="'text'"
      [placeholder]="'Search...'"
      [autoComplete]="'off'">
    </input-field>
    <mat-option *ngFor="let warehouse of filteredWarehouses; trackBy: trackById" 
      [value]="warehouse.id">
      {{ warehouse.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field *ngIf="filteredBusiness?.length > 0" appearance="standard">
  <mat-label>Businesses</mat-label>
  <mat-select [formControl]="businessControl" multiple>
    <input-field
      (valueChanged)="filterBusinesses($event)"
      [type]="'text'"
      [placeholder]="'Search...'"
      [autoComplete]="'off'">
    </input-field>
    <mat-option *ngFor="let business of filteredBusiness" [value]="business.business_id">{{ business.name }}</mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="standard">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input />
  </mat-form-field>
  <mat-form-field appearance="standard">
    <mat-label>Courier Codes</mat-label>
    <mat-select (selectionChange)="applyCourierCodeFilter($event)" [formControl]="courierCodeFilterControl">
        <mat-option>--</mat-option>
        <mat-option *ngFor="let courierCode of courierCodeFilters" [value]="courierCode">
          {{courierCode}}
        </mat-option>
      </mat-select>
  </mat-form-field>
<table mat-table matSort [dataSource]="dataSource">
    <ng-container matColumnDef="BusinessReference">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Business Reference</th>
      <td mat-cell *matCellDef="let element">{{ element.BusinessReference }}</td>
    </ng-container>
  
    <ng-container matColumnDef="OrderWaybillNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Waybill</th>
      <td mat-cell *matCellDef="let element">{{ element.OrderWaybillNumber }}</td>
    </ng-container>
  
    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Name</th>
      <td mat-cell *matCellDef="let element">{{ element.Name }}</td>
    </ng-container>
  
    <ng-container matColumnDef="CourierName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Courier</th>
      <td mat-cell *matCellDef="let element">{{ element.CourierName }}</td>
    </ng-container>

    <ng-container matColumnDef="CourierCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Courier Code</th>
        <td mat-cell *matCellDef="let element">{{ element.CourierCode }}</td>
      </ng-container>

      <ng-container matColumnDef="BusinessName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Business</th>
        <td mat-cell *matCellDef="let element">{{ element.BusinessName }}</td>
      </ng-container>

      <ng-container matColumnDef="WarehouseName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Warehouse</th>
        <td mat-cell *matCellDef="let element">{{ element.WarehouseName }}</td>
      </ng-container>

      <ng-container matColumnDef="LastEventName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Event</th>
        <td mat-cell *matCellDef="let element">{{ element.LastEventName }}</td>
      </ng-container>
  
    <ng-container matColumnDef="Timestamp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Updated</th>
      <td class="pd-l-5 {{ element.Timestamp?.toMillis() | timeAgoColour }}" mat-cell *matCellDef="let element">
        {{ element.Timestamp?.toMillis() | timeAgo }}
      </td>
    </ng-container>
  
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td class="actions" mat-cell *matCellDef="let element">
        <mat-icon matTooltip="View" (click)="viewTrip(element.CourierOrderId)"
          >visibility</mat-icon
        >
        <mat-icon matTooltip="Copy to Clipboard" (click)="copyToClipboard(element.CourierOrderId)"
          >content_copy</mat-icon
        >
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageSize]="pageSize" showFirstLastButtons> </mat-paginator>