export enum NotesCategories {
  CustomerCancelled = 'Customer Cancelled',
  NoDriverFound = 'No Driver Found',
  Finance = 'Finance',
  CustomerService = 'Customer Service',
  DriverSupport = 'Driver Support',
  DriverMisconduct = 'Driver Misconduct',
  DriverRecruitment = 'Driver Recruitment',
  DriverCompliment = 'Driver Compliment',
  TechSupport = 'TechSupport',
  Sales = 'Sales',
  CSM = 'CSM',
  OPS_MNG = 'OPS_MNG',
  NoPlusdriverfound = 'No Plus Driver Found',
  BusinessOperationsManager = 'Business Operations Manager',
  PeopleOperationsManager = 'People Operations Manager',
  DriverSuccessManager = 'Driver Success Manager',
  AccountRepresentative = 'Account Representative',
  DriverController = 'Driver Controller',
  DriverOps2IC = 'Driver Ops 2IC',
  DriverOpsTL = 'Driver Ops TL',
  PortfolioManager = 'PortfolioManager',
  OrderSentAfterCloseofBusiness = 'Order Sent After Close of Business'
}

export enum NotesTypeEnum {
  Order = 'Order',
  Driver = 'Driver',
  Bucket = 'Bucket'
}

export interface notesModel{
  author_id: string;
  owner: string;
  author_name: string;
  order_id: string;
  bucket_id: string;
  driver_id: string;
  read_by: string[];
  driver_name: string;
  origin: string;
  content: string;
  category: string;
  public: boolean;
  timestamp?: number;
}
