import { MaintenanceService } from './../../../maintenance.service';
import { ActingAs, AuthUser, UserRoles } from './../../../interfaces/auth.interfaces';
import { selectorActingAs, selectorUser } from './../../../auth/auth.reducer';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AssignDriverloadComponent } from 'app/dashboard/new-trips/driver-load/assign-driverload/assign-driverload.component';
import { AssignParcelsComponent } from 'app/dashboard/new-trips/driver-load/assign-parcels/assign-parcels.component';
import { DriverLoadService } from 'app/dashboard/new-trips/driver-load/driver-load.service';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ModalSizes } from 'app/shared/modals/modal.interfaces';
import { SimpleModalService } from 'ngx-simple-modal';
import { ReconcileWarehouseModalComponent } from '../reconcile-route/reconcile-warehouse-modal.component';
import { LastMileService } from '../last-mile.service';
import { selectorActiveTripWarehouseId } from 'app/dashboard/new-trips/store/new-trips.reducer';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { Parcel } from 'app/dashboard/new-trips/new-trips.interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TableColumn, TableTypeEnum } from 'app/shared/generic-modular-table/generic-modular-table.interfaces';
import { SortDirection } from 'app/shared/table/table.interfaces';
import { MatDialog } from '@angular/material/dialog';
import { TableSelectModalComponent } from 'app/shared/modals/table-select-modal/table-select-modal.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-last-mile-fs-parcels',
  templateUrl: './last-mile-fs-parcels.component.html',
  styleUrls: ['./last-mile-fs-parcels.component.scss'],
})
export class LastMileFsParcelsComponent implements OnChanges, OnDestroy {
  actingAs: ActingAs;
  UserRoles = UserRoles;
  warehouseId: string;
  constructor(
    private driverLoadService: DriverLoadService,
    private store: Store<any>,
    private simpleModalService: SimpleModalService,
    private maintenanceService: MaintenanceService,
    private lastMileService: LastMileService,
    private dialog: MatDialog
  ) {
    this.store.select(selectorActingAs).pipe(takeUntil(this.unsubscribe$)).subscribe((next) => (this.actingAs = next));
    this.store.select(selectorActiveTripWarehouseId).pipe(takeUntil(this.unsubscribe$)).subscribe((next) => (this.warehouseId = next));
    this.store.select(selectorUser).pipe(takeUntil(this.unsubscribe$)).subscribe((next: AuthUser) => this.user = next)
  }

  @Input() orderId;
  @Input() lastMileId: string;
  @Input() Parcels: Parcel[];
  @Output() refreshDataEvent = new EventEmitter();

  reconciling;
  businessReferenceBreakdown;
  processing;
  canTransfer = false;
  canUnassign = true;

  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public ModalSizes = ModalSizes;
  public UiThemes = UiThemes;
  public JustifyContent = JustifyContent;
  buttonTypes = ButtonTypes;
  user: AuthUser
  private unsubscribe$ = new Subject<void>();

  ngOnChanges(): void {
    if (this.Parcels) {
      this.createSummaryData(Object.values(this.Parcels));
      this.checkAbilities();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  clearLastMile(): void {
    this.processing = true;
    this.maintenanceService
      .clearLastMile(this.lastMileId)
      .then(() => {
        this.processing = false;
        this.refreshDataEvent.emit();
      })
      .catch(() => {
        this.processing = false;
        this.refreshDataEvent.emit();
      });
  }

  checkAbilities(): void {
    const parcelArray: any[] = Object.values(this.Parcels);
    if (
      parcelArray.some((parcel) => {
        return parcel.ActiveRouteId;
      })
    ) {
      this.canUnassign = false;
      this.canTransfer = false;
      return;
    }
    if (
      parcelArray.some((parcel) => {
        return parcel.LastMileParcelState === 'Collected' || parcel.LastMileParcelState === 'OutForDelivery';
      })
    ) {
      this.canUnassign = false;
      this.canTransfer = true;
    }
    if (parcelArray.some((parcel) => parcel.LastMileParcelState === 'FailedDelivery')) {
      this.canTransfer = true;
    }
    if (
      parcelArray.every((parcel) => {
        return parcel.LastMileParcelState === 'Delivered' || parcel.LastMileParcelState === 'Returned';
      })
    ) {
      this.canUnassign = false;
    }
  }

  unassignDriver(): void {
    this.processing = true;
    const parcelWaybills = [];
    const parcels = Object.values(this.Parcels);
    parcels.forEach((parcel: any) => {
      parcelWaybills.push(parcel.ParcelWaybill);
    });
    this.driverLoadService
      .clearDriverLoad(this.lastMileId, this.orderId, null)
      .then(() => {
        this.processing = false;
        this.refreshDataEvent.emit();
      })
      .catch(() => (this.processing = false));

  }

  unassignParcels(): void {
    this.processing = true;
    let parcelWaybills = [];
    const parcels = Object.values(this.Parcels);
    parcels.forEach((parcel: any) => {
      parcelWaybills.push(parcel.ParcelWaybill);
    });

    const dialogRef = this.dialog.open(TableSelectModalComponent, {
      data: {
        tableData: new MatTableDataSource(parcels),
        tableColumns: this.tableColumnsSetup(),
        heading: 'Unassign Orders'
      },
      panelClass: 'noBorder',
      width: '900px',
      height: '440px'
    });
    dialogRef.afterClosed().subscribe({
      next: (value: Parcel[]) => {
        if(value) {
          parcelWaybills = value.map(parcel => {
            return parcel.ParcelWaybill
          })

          this.driverLoadService
            .clearDriverLoad(this.lastMileId, null ,parcelWaybills)
            .then(() => {
              this.refreshDataEvent.emit();
            })
        }
      },
      complete: () => {
        this.processing = false;
      }
    })
  }

  transferParcels(): void {
    const orderId = this.orderId;
    const lastMileId = this.lastMileId;
    const Parcels = Object.values(this.Parcels);
    const modal_data = {
      Parcels: this.Parcels,
      warehouse_id: this.warehouseId,
      assign: false,
    };
    this.simpleModalService
      .addModal(AssignParcelsComponent,
        {
          orderId,
          Parcels,
          assign: false,
          lastMileId,
        })
      .subscribe((result) => {
        if (!result) {
          return;
        }
        this.simpleModalService.addModal(AssignDriverloadComponent, modal_data).subscribe((result2) => {
          if (!result2) {
            return;
          }
          this.driverLoadService.transferParcelsToDriver(result, result2.driverId, lastMileId).then(() => {
            this.refreshDataEvent.emit();
          });
        });
      });
  }

  reconcileRoute(): void {
    const modal_data = {
      Parcels: Object.values(this.Parcels),
      route_type: 'waypoint',
    };
    this.simpleModalService
      .addModal(ReconcileWarehouseModalComponent, modal_data)
      .subscribe((result: { parcel_waybill: string; reason: string; result: string }[]) => {
        if (!result) {
          this.reconciling = false;
          return;
        }
        this.lastMileService
          .reconcileParcels(result, this.lastMileId)
          .then(() => {
            this.reconciling = false;
            this.refreshDataEvent.emit();
          })
          .catch(() => {
            this.reconciling = false;
          });
      });
  }

  reduceParcelDetails(parcel) {
    return {
      Dimensions: parcel.Dimensions,
      ParcelReference: parcel.ParcelReference,
      OneTimePin: parcel.OneTimePin,
      ParcelWaybill: parcel.ParcelWaybill,
      ParcelValue: parcel.ParcelValue,
      PaymentType: parcel.PaymentType,
      LastMileParcelState: parcel.LastMileParcelState,
      Barcode: parcel.Barcode,
      ActiveRouteId: parcel.ActiveRouteId,
      ReconciledReason: parcel.ReconciledReason,
      FailedReason: parcel.FailedReason,
    };
  }

  createSummaryData(parcels): void {
    this.businessReferenceBreakdown = {};
    parcels.forEach((parcel) => {
      const parcelBusinessReference = parcel.BusinessReference;
      const origin = parcel.OriginWarehouse ? parcel.OriginWarehouse : parcel.Origin;
      const destination = parcel.DestinationWarehouse ? parcel.DestinationWarehouse : parcel.Destination;
      if (Object.keys(this.businessReferenceBreakdown).includes(parcelBusinessReference)) {
        this.businessReferenceBreakdown[parcelBusinessReference].Parcels.push(this.reduceParcelDetails(parcel));
      } else {
        this.businessReferenceBreakdown[parcelBusinessReference] = {
          Parcels: [this.reduceParcelDetails(parcel)],
          Origin: origin,
          OneTimePin: parcel.OneTimePin,
          LastMileId: parcel.LastMileId,
          Destination: destination,
          // TODO: Warren needs to put back Owner Object
          // "BusinessId": parcel.Owner.BusinessId,
          OrderId: parcel.OrderId,
          BusinessReference: parcel.BusinessReference,
        };
      }
    });
    this.businessReferenceBreakdown = Object.values(this.businessReferenceBreakdown);
    // Clean up undefined items from above loop
  }

  tableColumnsSetup(): TableColumn[]{
    return [{
      id: 'select',
      displayName: 'select',
      hidden: false,
      sortable: false,
      type: TableTypeEnum.checkbox,
    },
    {
      id: 'businessName',
      displayName: 'Business Name',
      hidden: false,
      sortable: true,
      sortDirection: SortDirection.ASC,
      type: TableTypeEnum.string,
      format: (value: Parcel) => {
        return value.BusinessName;
      }
    },
    {
      id: 'reference',
      displayName: 'Reference',
      hidden: false,
      sortable: true,
      sortDirection: SortDirection.ASC,
      type: TableTypeEnum.string,
      format: (value: Parcel) => {
        return value.CustomerReference ?? value.CourierReference;
      }
    },
    {
      id: 'waybill',
      displayName: 'Parcel Waybill',
      hidden: false,
      sortable: true,
      sortDirection: SortDirection.ASC,
      type: TableTypeEnum.string,
      format: (value: Parcel) => {
        return value.ParcelWaybill;
      }
    },
    {
      id: 'state',
      displayName: 'Parcel State',
      hidden: false,
      sortable: true,
      sortDirection: SortDirection.ASC,
      type: TableTypeEnum.string,
      format: (value: Parcel) => {
        return value.OrderParcelState ?? value.CourierParcelState;
      }
    }
    ]

  }

  // picupAdminOnlyOnPicupOrders(): boolean {
  //   return (this.user.is_admin &&
  //   (this.fsOrderDetails?.FleetAllocation === 'Picup' ||
  //   this.fsOrderDetails.FleetAllocation === 'PicupPlus')) ||
  //   this.fsOrderDetails?.FleetAllocation === 'Contractor'
  // }
}
