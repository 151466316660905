/* eslint-disable no-bitwise */
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
// import * as polylineTool from '@mapbox/polyline';

@Component({
  selector: 'app-map-polyline',
  templateUrl: './map-polyline.component.html',
  styleUrls: ['./map-polyline.component.scss'],
})
export class MapPolylineComponent implements OnInit, OnDestroy {
  @Input() polyline: string[];
  @Input() mapElement: google.maps.Map;
  @Input() set polylineColorIn(colour: string) {
    if(colour){
      this.polylineColor = colour;
      this.renderedPolylines.forEach((polyline) => {
        polyline.setMap(null);
      });
      this.generatePolylines();
    }
  }
  @Input() polylineType = 'routific';
  renderedPolylines = [];
  polylineColor= '#26b7fc'

  constructor() {}

  convertRoutificPoly(poly): string {
    const test = this.decodePolylineWithPrecision(poly, 6);
    const result = this.encodeWithPrecision(test)
    return result;
  }

  generatePolylines(): void {
    if (this.polyline?.length) {
      this.polyline.forEach((poly: string) => {
        if (poly) {
          if (this.polylineType === 'routific') {
            poly = this.convertRoutificPoly(poly);
          }
          const decodedPathAgain = google.maps.geometry.encoding.decodePath(poly);
          const polyline = new google.maps.Polyline({
            path: decodedPathAgain,
            strokeColor: this.polylineColor,
            strokeOpacity: 1,
            strokeWeight: 2,
          });
          polyline.setMap(this.mapElement);
          this.renderedPolylines.push(polyline);
        }
      });
    }
  }

  ngOnInit(): void {
    this.generatePolylines();
  }

  ngOnDestroy(): void {
    this.renderedPolylines.forEach((polyline) => {
      polyline.setMap(null);
    });
  }


  /// Test encoding of polyline
  decodePolylineWithPrecision(str, precision) {
    let index = 0;
    let lat = 0;
    let lng = 0;
    const coordinates = [];
    let shift = 0;
    let result = 0;
    let byte = null;
    let latitude_change;
    let longitude_change;
    const factor = Math.pow(10, Number.isInteger(precision) ? precision : 5);

    // Coordinates have variable length when encoded, so just keep
    // track of whether we've hit the end of the string. In each
    // loop iteration, a single coordinate is decoded.
    while (index < str.length) {

      // Reset shift, result, and byte
      byte = null;
      shift = 0;
      result = 0;

      do {
        byte = str.charCodeAt(index++) - 63;
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);

      latitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));

      shift = result = 0;

      do {
        byte = str.charCodeAt(index++) - 63;
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);

      longitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));

      lat += latitude_change;
      lng += longitude_change;

      coordinates.push([lat / factor, lng / factor]);
    }

    return coordinates;
  }


  /**
     * Encodes the given [latitude, longitude] coordinates array.
     *
     * @param {Array.<Array.<Number>>} coordinates
     * @param {Number} precision
     * @returns {String}
     */

  encodeWithPrecision(coordinates, precision = 5) {
    if (!coordinates.length){
      return '';
    }

    const factor = Math.pow(10, Number.isInteger(precision) ? precision : 5);
    let output = this.encode(coordinates[0][0], 0, factor) + this.encode(coordinates[0][1], 0, factor);

    for (let i = 1; i < coordinates.length; i++) {
      const a = coordinates[i], b = coordinates[i - 1];
      output += this.encode(a[0], b[0], factor);
      output += this.encode(a[1], b[1], factor);
    }

    return output;
  }

  encode(current, previous, factor) {
    current = this.py2_round(current * factor);
    previous = this.py2_round(previous * factor);
    let coordinate = current - previous;
    coordinate <<= 1;
    if (current - previous < 0) {
      coordinate = ~coordinate;
    }
    let output = '';
    while (coordinate >= 0x20) {
      output += String.fromCharCode((0x20 | (coordinate & 0x1f)) + 63);
      coordinate >>= 5;
    }
    output += String.fromCharCode(coordinate + 63);
    return output;
  }

  py2_round(value: number): number {
    // Google's polyline algorithm uses the same rounding strategy as Python 2, which is different from JS for negative values
    return Math.floor(Math.abs(value) + 0.5) * (value >= 0 ? 1 : -1);
  }
}
