import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectorActingAs } from 'app/auth/auth.reducer';
import axios from 'app/api/axios';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { AuthService } from 'app/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import firebase from 'firebase/app';
import { DriverAssignedOrders } from '../new-trips/new-trips.interfaces';
import { DriverDetails } from 'app/shared/assign-driver-modal/assign-driver-modal.interface';
import { BusinessDrivers } from './fleet-overview/fleet-overview.component';
import { LastMile } from 'app/shared/shared.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ManageFleetService {
  actingAs;
  driverSubscription;

  constructor(
    public store: Store<any>,
    private firestore: AngularFirestore,
    private authService: AuthService,
    private notificationsService: NotificationsService
  ) {
    this.store.select(selectorActingAs).subscribe((next) => {
      if (next) {
        this.actingAs = next;
      }
    });
  }

  setEndRouteLocationForDriver(driverId, endRouteLocation) {
    return axios({
      method: 'POST',
      url: '/driver/' + driverId + '/set-route-end-location',
      data: {
        latitude: endRouteLocation.latitude,
        longitude: endRouteLocation.longitude,
        formatted_address: endRouteLocation.formatted_address,
      },
    })
      .then((res) => {
        this.notificationsService.publish({ type: 'success', message: 'Route End Location Set' });
        return res;
      })
      .catch((error) => {
        this.notificationsService.publish({ type: 'error', message: error.response?.data.message });
        throw error.response?.data.message;
      });
  }

  clearEndRoutLocation(driverId) {
    return axios({
      method: 'POST',
      url: '/driver/' + driverId + '/clear-route-end-location',
      data: {},
    })
      .then(() => {
        this.notificationsService.publish({ type: 'success', message: 'Route End Location Cleared' });
      })
      .catch((error) => {
        this.notificationsService.publish({ type: 'error', message: error.response?.data.message });
      });
  }

  getBusinessDrivers(business_id: string): Promise<BusinessDrivers[]> {
    return axios({
      method: 'POST',
      url: '/generic/cqrs/get-drivers-for-business',
      data: {
        business_id,
      },
    }).then((response) => {
      return response?.data.output;
    });
  }

  updateVehicle(vehicle_model, vehicle_id) {
    return axios({
      method: 'POST',
      url: '/fleet/' + vehicle_id + '/update',
      data: vehicle_model,
    })
      .then((res) => {
        this.notificationsService.publish({ type: 'success', message: 'Vehicle Updated' });
        this.authService.updateAccount(this.actingAs.id);
        return res;
      })
      .catch((error) => {
        throw error.response?.data.message;
      });
  }

  addVehicle(vehicle_data) {
    return axios({
      method: 'POST',
      url: '/fleet/create',
      data: vehicle_data,
    })
      .then((res) => {
        this.notificationsService.publish({ type: 'success', message: 'Vehicle Created' });
        this.authService.updateAccount(this.actingAs.id);
        return res;
      })
      .catch((error) => {
        throw error.response?.data.message;
      });
  }

  deleteVehicle(vehicle_id) {
    return axios({
      method: 'DELETE',
      url: '/fleet/' + vehicle_id,
    })
      .then((res) => {
        this.notificationsService.publish({ type: 'success', message: 'Vehicle Deleted' });
        this.authService.updateAccount(this.actingAs.id);
        return res.data;
      })
      .catch((error) => {
        throw error.response?.data.message;
      });
  }

  unassignTelematics(vehicle_id) {
    return axios({
      method: 'POST',
      url: '/fleet/' + vehicle_id + '/unassign-telematics-id',
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error.response?.data.message;
      });
  }

  assignTelematics(vehicle_id, telematics_id) {
    return axios({
      method: 'POST',
      url: '/fleet/' + vehicle_id + '/assign-telematics-id',
      data: { telematics_id: telematics_id },
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error.response?.data.message;
      });
  }

  removeDriver(driver_id) {
    return axios({
      method: 'POST',
      url: '/business/' + this.actingAs.id + '/remove-driver',
      data: { driver_id: driver_id },
    })
      .then((res) => {
        this.notificationsService.publish({ type: 'success', message: 'Driver Removed' });
        return res.data;
      })
      .catch((error) => {
        this.notificationsService.publish({ type: 'error', message: 'Operation Failed' });
        throw error.response?.data;
      });
  }

  queryDriverByEmail(email_address) {
    return axios({
      method: 'POST',
      url: '/driver/query-by-email',
      data: { email: email_address },
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  createDriver(driver) {
    const data = {
      Drivers: [driver],
    };
    return axios({
      method: 'POST',
      url: '/driver/batch-create',
      data: data,
    })
      .then((res) => {
        if (res.data?.errors?.length > 0) {
          this.notificationsService.publish({ type: 'error', message: 'Driver Creation Failed' });
          throw res.data.errors;
        } else {
          this.notificationsService.publish({ type: 'success', message: 'Driver Created' });
        }
        return res.data;
      })
      .catch((error) => {
        this.notificationsService.publish({ type: 'error', message: 'Driver Creation Failed' });
        throw error.response?.data;
      });
  }

  addDriverToBusiness(driver_id) {
    return axios({
      method: 'POST',
      url: 'business/' + this.actingAs.id + '/add-driver',
      data: { driver_id: driver_id },
    })
      .then((response) => {
        this.notificationsService.publish({ type: 'success', message: 'Driver Added' });
        return response;
      })
      .catch((error) => {
        this.notificationsService.publish({ type: 'error', message: error.response?.data.message });
      });
  }

  getLotteryOptions() {
    return axios({
      method: 'GET',
      url: 'enterprise/' + this.actingAs.id + '/get-lottery-options',
    }).then((response) => {
      return response?.data;
    });
  }

  setLotteryOptions(data) {
    return axios({
      method: 'POST',
      url: 'enterprise/' + this.actingAs.id + '/set-lottery-options',
      data: {
        lottery_options: data,
      },
    })
      .then(() => {
        this.authService.updateAccount(this.actingAs.id);
        this.notificationsService.publish({ type: 'success', message: 'Lottery Options Set' });
      })
      .catch((error) => {
        this.notificationsService.publish({ type: 'error', message: error.response?.data.message });
      });
  }

  getDriversState(driverIds: string[]): Observable<firebase.firestore.QuerySnapshot<DriverState>> {
    return this.firestore
      .collection<DriverState>('driver-states', (ref) => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (driverIds) {
        query = query.where(firebase.firestore.FieldPath.documentId(), 'in', driverIds);
      }
      return query;
    })
      .get();
  }

  getDriversAssignedOrders(driverIds: string[]): Observable<firebase.firestore.QuerySnapshot<DriverAssignedOrders>> {
    return this.firestore
      .collection<DriverAssignedOrders>('driver-assigned-orders', (ref) => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (driverIds) {
        query = query.where(firebase.firestore.FieldPath.documentId(), 'in', driverIds);
      }
      return query;
    })
      .get();
  }

  getDriversDetails(driverIds: string[]): Observable<firebase.firestore.QuerySnapshot<DriverDetails>> {
    return this.firestore
      .collection<DriverDetails>('driver-details', (ref) => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (driverIds) {
        query = query.where(firebase.firestore.FieldPath.documentId(), 'in', driverIds);
      }
      return query;
    })
      .get();
  }

  getDriversLastMile(driverIds: string[]): Observable<firebase.firestore.QuerySnapshot<LastMile>> {
    return this.firestore
      .collection<LastMile>('last-mile', (ref) => {
      if (driverIds.length > 0) {
        return ref.where('DriverId', 'in', driverIds)
      }
      return ref;
    })
      .get();
  }

  getLastMilesById (lastMileIds: string[]): Observable<firebase.firestore.QuerySnapshot<LastMile>> {
    return this.firestore
      .collection<LastMile>('last-mile', (ref) => {
      if (lastMileIds.length > 0) {
        return ref.where('Id', 'in', lastMileIds)
      }
      return ref;
    })
      .get();
  }
}

export interface DriverState {
  active_vehicle: string;
  online: boolean;
  active_route_id: string;
  active_last_mile_id: string;
  todays_orders: string[];
  location: { longitude: number; latitude: number; }
}

