<!-- <div class="modal">
  <div class="modal-content">
    <heading [icon]="Icons.Types.Business" [color]="Icons.Colors.Blue">
      <h4 class="heading-title">Set Webhook URL</h4>
    </heading>

    <div class="modal-body">
      <div class="form-wrap">
        <div class="row">
          <app-grid-container [columns]="'repeat(1, 1fr)'" [theme]="UiThemes.Light" [rowGap]="'2rem'" [colGap]="'2rem'">
            <input-field
              [type]="'text'"
              (valueChanged)="webhookURL = $event"
              [value]="webhookURL"
              [label]="'Webhook URL'"
            ></input-field>
          </app-grid-container>
        </div>
      </div>
      <div class="modal-footer">
        <app-basic-button [title]="'Cancel'" [type]="buttonTypes.Tertiary" (click)="cancel()"></app-basic-button>
        <app-basic-button
          [disabled]="!webhookURL"
          [title]="'Confirm'"
          [type]="buttonTypes.Secondary"
          (click)="setWebhookURL()"
        ></app-basic-button>
      </div>
    </div>
  </div>
</div> -->

<app-modal
  [open]="true"
  [actions]="actions"
  [size]="ModalSizes.Large"
  [highlightPrimaryAction]="true"
  (handleAction)="handleAction($event)"
>
  <heading [icon]="Icons.Types.Business" [color]="uiColors.Blue">
    <h4 class="heading-title">{{ 'Set Webhook' }}</h4>
  </heading>
  <div class="modal-body">
    <app-grid-container [columns]="'repeat(1, 1fr)'" [colGap]="'2rem'" [rowGap]="'1rem'" [padding]="'2rem'">
      <div>
        <label> Authentication: </label>
        <app-page-filter-buttons
          *ngIf="sectionIds"
          [highlight]="true"
          (sectionSelected)="selectedSection = $event"
          [selectedSection]="selectedSection"
          [sectionIds]="sectionIds"
        ></app-page-filter-buttons>
      </div>
      <input-field
        [type]="'text'"
        [labelTheme]="'dark'"
        (valueChanged)="webhook_model.webhook_url = $event"
        [autoComplete]="'off'"
        [value]="webhook_model.webhook_url"
        [label]="'URL'"
        [required]="true"
      >
      </input-field>

      <div *ngIf="selectedSection === 'api-key'">
        <input-field
          [type]="'text'"
          [labelTheme]="'key'"
          (valueChanged)="webhook_model.api_key = $event"
          [autoComplete]="'off'"
          [value]="webhook_model.api_key"
          [label]="'API KEY (KEY: api-key)'"
          [required]="true"
        >
        </input-field>
      </div>
      <div *ngIf="selectedSection === 'basic'">
        <app-grid-container [columns]="'repeat(2, 1fr)'" [colGap]="'2rem'" [rowGap]="'1rem'" [padding]="'0'">
          <input-field
            [type]="'text'"
            [labelTheme]="'key'"
            (valueChanged)="webhook_model.user_name = $event"
            [autoComplete]="'off'"
            [value]="webhook_model.user_name"
            [label]="'USERNAME  (key: user_name)'"
            [required]="true"
          >
          </input-field>
          <input-field
            [type]="'password'"
            [labelTheme]="'key'"
            (valueChanged)="webhook_model.password = $event"
            [autoComplete]="'off'"
            [value]="webhook_model.password"
            [label]="'PASSWORD (key: password)'"
            [required]="true"
          >
          </input-field>
        </app-grid-container>
      </div>
    </app-grid-container>
  </div>
</app-modal>
