import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NotesTypeEnum, notesModel } from '../notes.constants';
import { NotesService } from '../notes.service';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { Moment } from 'moment';
import moment from 'moment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-notes-modal',
  templateUrl: './notes-modal.component.html',
  styleUrls: ['./notes-modal.component.scss']
})
export class NotesModalComponent implements OnInit {

  @Input() id: string;
  @Input() notesType: NotesTypeEnum;
  @Input() isAdmin: boolean;

  buttonTypes = ButtonTypes;
  iconTypes = IconTypes;
  uiColors = UiColors;
  UiThemes = UiThemes;

  get orderNotes$(): Observable<notesModel[]> {
    return this.notesService.orderNotes$;
  }

  constructor(private notesService: NotesService) {}

  ngOnInit(): void {
    if(this.id){
      this.notesService.getOrderNotes(this.id);
    }
  }

  openOrder(): void{
    window.open(window.location.origin + '/dashboard/orders/trip/' + this.id, '_blank')
  }

  convertTime(timestamp: any): string | Moment {
    if (!timestamp) {
      return 'Now';
    } else {
      return moment(timestamp.seconds, 'X').format('lll');
    }
  }

}
