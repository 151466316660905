import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { selectorActingAs } from 'app/auth/auth.reducer';
import { AssignedStatusEnum, OpTrBusinesses, PieChartData } from 'app/operations-tracking/operations-tracking.interface';
import { OperationsTrackingService } from 'app/operations-tracking/operations-tracking.service';
import { HumanizePipe } from 'app/shared/pipes/humanize.pipe';
import { CourierOrder } from 'app/shared/shared.interfaces';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { OrderTrackingComponent } from '../order-tracking/order-tracking.component';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { takeUntil } from 'rxjs/operators';
import { ActingAs, Warehouse } from 'app/interfaces/auth.interfaces';
import { TeamDetails, WarehouseDetails } from 'app/admin/team-management/Interfaces/team-management';

interface FlatCourierOrder {
  CourierOrderId: string;
  BusinessReference: string;
  OrderWaybillNumber: string;
  Name: string;
  CourierName: string;
  CourierCode: string;
  BusinessName: string;
  WarehouseName: string;
  LastEventName: string;
  Timestamp: any;
}

@Component({
  selector: 'app-courier-orders',
  templateUrl: './courier-orders.component.html',
  styleUrls: ['./courier-orders.component.scss'],
})
export class CourierOrdersComponent extends OrderTrackingComponent implements OnInit, OnDestroy, AfterViewInit {
  businessId: string;
  subscription: Subscription;
  dataSource = new MatTableDataSource<FlatCourierOrder>([]);
  courierOrders: CourierOrder[] = [];
  displayedColumns: string[] = this.isAdmin ? [
    'BusinessReference',
    'OrderWaybillNumber',
    'Name',
    'CourierName',
    'CourierCode',
    'BusinessName',
    'WarehouseName',
    'LastEventName',
    'Timestamp',
    'Actions',
  ] : ['BusinessReference',
    'OrderWaybillNumber',
    'Name',
    'CourierName',
    'CourierCode',
    'WarehouseName',
    'LastEventName',
    'Timestamp',
    'Actions',];
  warehouseFilters: string[] = [];
  courierCodeFilters: string[] = [];
  warehouseFilterControl: FormControl = new FormControl('');
  courierCodeFilterControl: FormControl = new FormControl('');
  warehouseControl = new FormControl([]);
  businessControl = new FormControl([]);
  filteredWarehouses = this.warehouses;
  filteredBusiness = this.businesses;
  teamsControl = new FormControl([]);
  teams: TeamDetails[] = [];
  courierParcelSummaryData: any;
  pageSize = 5;
  isAdminScreen: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public store: Store,
    public operationsTrackingService: OperationsTrackingService,
    public notificationsService: NotificationsService,
    private humanize: HumanizePipe) {
    super(notificationsService, operationsTrackingService, store);
    this.operationsTrackingService.usedIds$.pipe(takeUntil(this.unsubscribe$)).subscribe((ids: string[]) => {
      this.selectedIds = ids;
      this.getActiveCourierOrders();
    });
  }

  ngOnInit(): void {
    this.operationsTrackingService.isAdmin$.pipe(takeUntil(this.unsubscribe$)).subscribe((value: boolean) => {
      this.isAdminScreen = value;
    });
    this.operationsTrackingService.teams$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(teams => {
        this.teams = teams;
      });
    this.store.select(selectorActingAs)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((actingAs: ActingAs) => {
        const businessId = actingAs.id;
        this.getActiveCourierOrders();
        this.getPostDispatchSummary(businessId);
      });

    this.warehouseControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedWarehouses: string[]) => {
        this.operationsTrackingService.usedIds = [...selectedWarehouses];
        this.storeWarehouseFilterPerBusiness();
      });

    this.businessControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedBusinesses: string[]) => {
        this.operationsTrackingService.usedIds = [...selectedBusinesses];
        this.storeFilters();
      });

    this.teamsControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((teamNames: string[]) => {
        const warehouses = [];
        teamNames.forEach((teamName: string) => {
          const teamWarehouses = this.teams.find(value => value.teamName === teamName)?.warehouses ?? [];
          teamWarehouses.forEach((warehouse: WarehouseDetails) => {
            if(!warehouses.some(value => value === warehouse.id)){
              warehouses.push(warehouse.id)
            }
          })
        });
        this.warehouseControl.setValue(warehouses);
      });

    this.loadFilters()
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.page.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.storeFilters();
    })
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getPostDispatchSummary(businessId: string): void {
    this.operationsTrackingService.getPostDispatchSummary(businessId, []).then((res) => {
      const data: PieChartData[] = Object.keys(res.courier_summary.courier).filter((key) => key !== 'total').map((key) => {
        return {
          name: this.humanize.transform(key),
          value: res.courier_summary.courier[key],
        }
      });
      this.courierParcelSummaryData = data;
    });
  }

  getActiveCourierOrders(): void {
    const ids = this.selectedIds.length > 0? this.selectedIds :
      this.isAdmin ?
        this.businesses.map(business => {
          return business.business_id;
        }) : this.warehouses.map((warehouse) => {
          return warehouse.id
        });
    const batches: Observable<CourierOrder[]>[] = [];

    while (ids?.length) {
      const idBatch = ids.splice(0, 10);
      batches.push(this.operationsTrackingService.getActiveCourierOrders(idBatch, this.isAdmin));
    }

    this.subscription?.unsubscribe();
    this.subscription = combineLatest(batches).subscribe((ordersSummary:  CourierOrder[][]) => {
      let orders: CourierOrder[] = [];
      orders = orders.concat(...ordersSummary);
      this.courierOrders = orders;
      this.dataSource.data = this.courierCodeFilterControl.value
        ? this.flattenCourierOrders(orders.filter((order: CourierOrder) => order.CourierCode === this.courierCodeFilterControl.value))
        : this.flattenCourierOrders(orders);
      this.courierCodeFilters = Array.from(new Set(orders.map((order: CourierOrder) => order.CourierCode)));
    });
  }

  flattenCourierOrders(courierOrders: CourierOrder[]): FlatCourierOrder[] {

    return courierOrders.map((order) => {
      return {
        CourierOrderId: order.Id,
        BusinessReference: order.BusinessReference,
        CourierCode: order.CourierCode,
        CourierName: order.CourierName,
        LastEventName: order.LastEventName,
        Name: order?.Parcels[0]?.Destination?.Contact?.Name,
        OrderWaybillNumber: order.OrderWaybillNumber,
        Timestamp: order.Timestamp,
        BusinessName: order.BusinessName,
        WarehouseName: order.WarehouseName,
      };
    });
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyCourierCodeFilter(change: MatSelectChange): void {
    if (!change.value) {
      this.dataSource.data = this.flattenCourierOrders(this.courierOrders);
      return;
    }
    this.dataSource.data = this.flattenCourierOrders(
      this.courierOrders.filter((order: CourierOrder) => {
        return order.CourierCode === change.value
      })
    );
  }

  viewTrip(courierOrderId: string): void {
    window.open(`${window.location.origin}/dashboard/courier-orders/order/${courierOrderId}`, '_blank');
  }

  copyToClipboard(courierOrderId: string): void {
    navigator.clipboard.writeText(courierOrderId);
  }

  filterWarehouse(searchTerm: string): void{
    if(searchTerm.length > 2 ){
      this.filteredWarehouses = this.warehouses.filter((warehouse: Warehouse) => {
        return warehouse?.name?.toLowerCase().includes(searchTerm.toLowerCase());
      })
    } else {
      this.filteredWarehouses = this.warehouses;
    }
  }

  filterBusinesses(searchTerm: string): void{
    if(searchTerm.length > 2 ){
      this.filteredBusiness = this.businesses.filter((business: OpTrBusinesses) => {
        return business?.name?.toLowerCase().includes(searchTerm.toLowerCase());
      })
    } else {
      this.filteredBusiness = this.businesses;
    }
  }

  trackById(_index: number, item: Warehouse): string{
    return item.id;
  }

  trackByTeamName(_index: number, item: TeamDetails): string {
    return item.teamName;
  }

  storeFilters(): void {
    const filtersGlobal = JSON.parse(localStorage.getItem('opsFilters'));
    const filterValues = {
      selectedBusinesses: this.businessControl.value,
      pageSize: this.paginator?.pageSize ?? this.pageSize,
      selectedFleet: filtersGlobal?.selectedFleet,
      selectedEvent: filtersGlobal?.selectedEvent,
      selectedOnDemand: filtersGlobal?.selectedOnDemand ?? 'All',
      selectedAssignedStatus: AssignedStatusEnum[filtersGlobal?.selectedAssignedStatus] ?? 'All',
    }
    localStorage.setItem('opsFilters', JSON.stringify(filterValues));
  }

  storeWarehouseFilterPerBusiness(): void {
    const fValues = {
      selectedWarehouses: this.warehouseControl.value,
      selectedTeams: this.teamsControl.value,
    }
    localStorage.setItem(`opsFilters-${this.businessId}`, JSON.stringify(fValues));
  }

  loadFilters(): void {
    const filtersGlobal = JSON.parse(localStorage.getItem('opsFilters'));
    this.pageSize = filtersGlobal?.pageSize ?? 5;

    if(this.isAdminScreen){
      this.businessControl.setValue(filtersGlobal?.selectedBusinesses ?? []);
    }

    if(!this.isAdminScreen){
      const filters = JSON.parse(localStorage.getItem(`opsFilters-${this.businessId}`));
      this.teamsControl.setValue(filters?.selectedTeams ?? []);
      this.warehouseControl.setValue(filters?.selectedWarehouses ?? []);
    }
  }
}
