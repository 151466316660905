import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectorActingAs } from 'app/auth/auth.reducer';
import { FleetAllocationEnum } from 'app/dashboard/new-trips/new-trips.interfaces';
import { NewTripsService } from 'app/dashboard/new-trips/new-trips.service';
import { LastMileFrontEndState, OrderFrontEndStateEnum } from 'app/dashboard/post-dispatch/post-dispatch.interfaces';
import { ActingAs, Warehouse } from 'app/interfaces/auth.interfaces';
import { OpTrBusinesses, OperationsTrackingTableAction, OperationsTrackingTableActionEnum, OrderSummary } from 'app/operations-tracking/operations-tracking.interface';
import { OperationsTrackingService } from 'app/operations-tracking/operations-tracking.service';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { LastMile, NotificationTypeEnum } from 'app/shared/shared.interfaces';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.scss']
})
export class OrderTrackingComponent implements OnDestroy {
  warehouses: Warehouse[] = [];
  warehouseSubscription: Subscription;
  teamsSubscription: Subscription;
  businessId: string;
  businesses: OpTrBusinesses[] = [];
  businessSubscription: Subscription;
  emptyWarehouse: Warehouse = {
    id: 'none',
    name: 'No warehouse',
    warehouse_id: null,
    warehouse_code: null,
    address: null,
    email: null,
    phone: null,
    latitude: 0,
    longitude: 0,
    suburb: null,
    postal_code: null,
    country: null,
    country_short_code: null,
    contact: {
      ClientAddress: null,
      Email: null,
      Instructions: null,
      Name: null,
      PhoneNumber: null
    },
    allow_business_consolidation: false,
    is_eligible_for_da_consolidation: false,
    default_special_instructions: null
  }
  displayedColumns = ['BusinessName', 'CustomerReference','WarehouseName','ScheduledDate','LastEventName', 'Timestamp', 'LotteryRestartCount', 'IsRoundTrip', 'FleetAllocation', 'AssignedTo','Parcels' ,'Actions']
  selectedIds: string[] = [];
  isAdmin: boolean
  unsubscribe$ = new Subject<void>();


  constructor(
    public notificationsService: NotificationsService,
    public operationsTrackingService: OperationsTrackingService,
    public store: Store,
    public newTripsService?: NewTripsService) {
    this.operationsTrackingService.isAdmin$.pipe(takeUntil(this.unsubscribe$)).subscribe((isAdmin: boolean) => {
      this.isAdmin = isAdmin
      if(isAdmin) {
        this.businessSubscription = this.operationsTrackingService.businesses$
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((data) => {
            this.businesses = data ? [...data] : [];
          })
        this.displayedColumns = ['BusinessName', 'CustomerReference','WarehouseName','ScheduledDate','LastEventName', 'Timestamp', 'LotteryRestartCount', 'IsRoundTrip', 'FleetAllocation', 'AssignedTo','Parcels' ,'Actions']
      } else {
        this.warehouseSubscription = this.operationsTrackingService.warehouses$
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((data: Warehouse[]) => {
            this.warehouses = data ? [ this.emptyWarehouse, ...data] : [];
          });

        this.displayedColumns = ['CustomerReference','WarehouseName','ScheduledDate','LastEventName', 'Timestamp', 'LotteryRestartCount', 'IsRoundTrip', 'FleetAllocation', 'AssignedTo','Parcels' ,'Actions']

      }
    })
    this.store.select(selectorActingAs).pipe(takeUntil(this.unsubscribe$)).subscribe((business: ActingAs) => (this.businessId = business.id));

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleTableAction(actionTaken: OperationsTrackingTableAction): void {
    switch (actionTaken.Action) {
      case OperationsTrackingTableActionEnum.CopyId: return this.copyId(actionTaken.Id);
      case OperationsTrackingTableActionEnum.RestartLottery: return this.restartLottery(actionTaken.Id);
      case OperationsTrackingTableActionEnum.View: return this.viewTrip(actionTaken.Id);
      case OperationsTrackingTableActionEnum.ViewLastMile: return this.viewLastMile(actionTaken.Id);
    }
  }

  viewTrip(orderId: string): void {
    window.open(`${window.location.origin}/dashboard/orders/trip/${orderId}`, '_blank');
  }

  restartLottery(orderId: string): void {
    this.newTripsService.startLotteryBackground(orderId, FleetAllocationEnum.Picup).then(() => {
      this.notificationsService.publish({ message: 'Lottery restart successful', type: NotificationTypeEnum.Success});
    });
  }

  copyId(orderId: string): void {
    navigator.clipboard.writeText(orderId);
    this.notificationsService.publish({ message: 'ID copied to clipboard', type: NotificationTypeEnum.Success});
  }

  getFirestoreBatchesForOrders(type: OrderFrontEndStateEnum): Observable<OrderSummary[]>[]{
    const ids = this.selectedIds.length > 0? this.selectedIds :
      this.isAdmin ?
        this.businesses.map(business => {
          return business.business_id;
        }) : this.warehouses.map((warehouse) => {
          return warehouse.id
        });
    const batches: Observable<OrderSummary[]>[] = [];
    if(ids.length === 0 && !this.isAdmin) {
      batches.push(this.getAllOrders(type));
      return batches
    }
    if(ids.findIndex((id: string) => id === 'none') > -1 && !this.isAdmin){
      if(type === OrderFrontEndStateEnum.CompletedAllFailedFinalCollection){
        batches.push(
          this.operationsTrackingService.getFinalFailedCollectionOrdersWithNullWarehouses(
            type
          )
        )
      } else {
        batches.push(
          this.operationsTrackingService.getOrdersByStateAndWithNullWarehouses(
            type
          )
        )
      }
    }
    while (ids?.length) {
      // we do by due to firestore limitation of values for an 'in' query
      const idBatch = ids.splice(0, 10);
      batches.push(this.operationsTrackingService.getOrderSummaryWithWarehouseOrBusinessIds(type, idBatch, this.isAdmin, type === OrderFrontEndStateEnum.CompletedAllFailedFinalCollection));
    }
    return batches;
  }

  getAllOrders(type: OrderFrontEndStateEnum): Observable<OrderSummary[]> {
    return this.operationsTrackingService
      .getOrdersByState(type);
  }

  getFirestoreBatchesForLastMiles(state: LastMileFrontEndState): Observable<LastMile[]>[]  {
    const ids = this.selectedIds.length > 0? this.selectedIds :
      this.isAdmin ?
        this.businesses.map(business => {
          return business.business_id;
        }) : this.warehouses.map((warehouse) => {
          return warehouse.id
        });
    const batches: Observable<LastMile[]>[] = [];
    if(ids.length === 0 && !this.isAdmin) {
      batches.push(this.operationsTrackingService.getAllLastMileFromBusiness(state === LastMileFrontEndState.Active));
      return batches
    }
    // firestore in query limit of 10 values
    while (ids?.length) {
      const warehouseBatch = ids.splice(0, 10);
      batches.push(this.operationsTrackingService.getLastMileWithWarehouseOrBusinessIds(warehouseBatch, this.isAdmin,state === LastMileFrontEndState.Active));
    }
    return batches
  }

  viewLastMile(lastMileId: string): void {
    window.open(`${window.location.origin}/dashboard/last-mile/${lastMileId}`, '_blank');
  }
}
